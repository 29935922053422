import React from "react";
import CTA from "./CTA";
import w from "../../../src/assets/w.svg";
import HeaderSocials from "./HeaderSocials";
const Header = () => {
  return (
    <header
      id="home"
      className="mt-[8rem] flex justify-center align-center h-full md:68vh md:mt-[11rem]  lg:mt-[5rem]"
    >
      <div className="w-10/12 fix-img-md relative flex-col align-center h-full text-center md:mb-[1rem]">
        <h5 className="font-medium text-2xl my-1 ">Hello I'm</h5>
        <h1 className="font-medium my-1 text-3xl">Mays Alazzawi</h1>
        <h5 className="font-medium text-xl my-1 text-light">
          Frontend Developer - UI/UX Designer
        </h5>
        <CTA />
        <HeaderSocials />
        <div className="overflow-hidden w-auto h-max pb-[25px] lg:pt-[3rem] pt-[5rem] bg-gradient-to-b from-primary  mt-14 lg:mt-10 flex align-center justify-center rounded-t-[200px]   ">
          <img
            src={w}
            alt="me"
            className="meImg flex justify-center align-center h-[20rem] "
          />
        </div>
        <a
          href="#contact"
          className="scroll__down absolute font-extralight text-normal text-primary right-[-2.3rem] bottom-[5rem] lg:bottom-[10rem] rotate-90 hidden md:flex"
        >
          Scroll Down
        </a>
      </div>
    </header>
  );
};
export default Header;
